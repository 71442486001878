import React, {useEffect, useState} from "react";

function HomePage() {
    const [contentHTLM, setContentHTLM] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch the JSON content from the CDN
        fetch('/proxy/content/home')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setContentHTLM(data.html);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching content:', error);
                setError(error);
                setLoading(false);
            });
    }, []); // Empty dependency array ensures this runs only once when the component mounts

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading content</div>;
    }

    return (
        <div className="bg-transparent mb-0 radius-0">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6">
                        <div className="lead mb-4 text-mono text-success">
                            <div dangerouslySetInnerHTML={{__html: contentHTLM}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;