import React from 'react';
import {Link} from "react-router-dom";

function renderAdminLink(role, enableAdmin) {
    if (enableAdmin && role === 'admin') {
        return <a href="/admin" className="p-3 text-decoration-none text-light">Admin</a>;
    }
    return null;
}

function renderSignInButton(user, handleSignOut, handleSignIn, enableAdmin) {
    if (enableAdmin) {
        return (<div className="ml-auto">
            {user ? (
                <>
                    <span className="p-3 text-light">Welcome, {user.displayName}</span>
                    <button onClick={handleSignOut} className="btn btn-light ml-2">
                        Sign Out
                    </button>
                </>
            ) : (
                <button onClick={handleSignIn} className="btn btn-light">
                    Sign In
                </button>
            )}
        </div>);
    }
    return null;
}

function Navbar({ user, role, handleSignIn, handleSignOut, enableAdmin = false }) {
    return (
        <div className="navbar-dark text-white">
            <div className="container">
                <nav className="navbar px-0 navbar-expand-lg navbar-dark">
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarNavAltMarkup"
                        aria-controls="navbarNavAltMarkup"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <Link to="/" className="pl-md-0 p-3 text-light">Home</Link>
                            <Link to="/about" className="p-3 text-decoration-none text-light">About</Link>
                            <Link to="/cfp" className="p-3 text-decoration-none text-light">CFP</Link>
                            <Link to="/schedule" className="p-3 text-decoration-none text-light">Schedule</Link>
                            <Link to="/events" className="p-3 text-decoration-none text-light">Events</Link>
                            <Link to="/code-of-conduct" className="p-3 text-decoration-none text-light">Code of Conduct</Link>
                            <Link to="/maps" className="p-3 text-decoration-none text-light">Map</Link>
                            <Link to="/ckc-101" className="p-3 text-decoration-none text-light">CkC 101</Link>
                            {renderAdminLink(role, enableAdmin)}
                        </div>
                        <div className="ml-auto">
                            {renderSignInButton(user, handleSignOut, handleSignIn, enableAdmin)}
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Navbar;
