import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Admin from "../components/admin/Admin";
import HomePage from "../components/shared/HomePage";
import useAuth from "./auth";
import AdminNavbar from "../components/admin/NavBar";
import AboutPage from "../components/shared/About";
import CFP from "../components/shared/cfp";
import Schedule from "../components/shared/Schedule";
import Events from "../components/shared/Events";
import CodeOfConduct from "../components/shared/CodeOfConduct";
import Maps from "../components/shared/Maps";
import CKC101 from "../components/shared/CKC101";

function App() {
    const { user, role } = useAuth();
    return (
        <div className="App">
            <AdminNavbar />
            <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="/about" element={<AboutPage />} />
                <Route exact path="/cfp" element={<CFP />} />
                <Route exact path="/schedule" element={<Schedule />} />
                <Route exact path="/events" element={<Events />} />
                <Route exact path="/code-of-conduct" element={<CodeOfConduct />} />
                <Route exact path="/maps" element={<Maps />} />
                <Route exact path="/ckc-101" element={<CKC101 />} />
                {
                    role === 'admin' ? <Route
                        path="/admin"
                        element={<Admin /> }
                    /> : <Route />
                }

            </Routes>
        </div>
    );
}

export default App;
