import React, { useState, useEffect } from 'react';
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { db } from '../../admin/firebaseConfig';

import Cke from "./ckeditor/cke";


function Admin() {
  const [content, setContent] = useState('');
  const [section, setSection] = useState('home');
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchHeaderText = async () => {
      const segments = ['content']
      const docRef = doc(db, section, ...segments);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setContent(docSnap.data().html);
      } else {
        setContent("")
      }
      setIsLoading(false);
    };
    fetchHeaderText();
  }, [db, section]);

  // Function to switch sections (home/about/etc)
  const handleSectionChange = (event) => {
    setSection(event.target.value);
    setIsLoading(true); // Show loading when changing sections
  };

  const handleContentChange = (editorContent) => {
    setContent(editorContent);
  };

  const updateContent = async () => {
    const segments = ['content']
    const docRef = doc(db, section, ...segments);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, { html: content });
      } else {
        console.error(`No document exists for the section: ${section}`);
        await setDoc(docRef, { html: content });
        console.log(`Document created for the section: ${section}`);
      }
      await updateContentJSON();
    } catch (error) {
      console.error("Error updating document:", error);
    }

  };

  const updateContentJSON = async () => {
    let url = `https://${process.env.ADMIN_API_ENDPOINT}`
    if(process.env.ADMIN_API_ENDPOINT === "localhost") {
      url = `http://${process.env.ADMIN_API_ENDPOINT}:${process.env.ADMIN_API_PORT}`
    }
    fetch(`${url}/api/update-data`, {method: "POST"})
        .then(response => response.text())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
  };

  const publish = async () => {
    let url = `https://${process.env.ADMIN_API_ENDPOINT}`
    if(process.env.ADMIN_API_ENDPOINT === "localhost") {
      url = `http://${process.env.ADMIN_API_ENDPOINT}:${process.env.ADMIN_API_PORT}`
    }
    fetch(`${url}/api/publish`, {method: "POST"})
        .then(response => response.text())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
  };

  if (isLoading) {
    return <div>Loading editor...</div>;
  }

  return (
      <div>
        <h1>Admin Panel</h1>
        {/* Dropdown or tab to select which section (home/about) to edit */}
        <div>
          <label>Select Section: </label>
          <select value={section} onChange={handleSectionChange}>
            <option value="home">Home</option>
            <option value="about">About</option>
            <option value="cfp">CFP</option>
            <option value="schedule">Schedule</option>
            <option value="events">Events</option>
            <option value="code-of-conduct">Code Of Conduct</option>
            <option value="maps">Maps</option>
            <option value="ckc-101">CKC 101</option>
          </select>
        </div>
        <div>
          <h2>{section.charAt(0).toUpperCase() + section.slice(1)} page</h2>
          <Cke initialData={content} onContentChange={handleContentChange}/>

          <div>
            <h3>Preview:</h3>
            <div className="lead mb-4 text-mono text-success">
              <div dangerouslySetInnerHTML={{__html: content}}></div>
            </div>
          </div>

          <button onClick={updateContent}>Update Content</button>
          <button onClick={publish}>Publish</button>
        </div>
      </div>
  );
}

export default Admin;
